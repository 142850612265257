@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: black;
  color: white;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 5px;          
}
body::-webkit-scrollbar-track {
  background: transparent;     
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(80, 80, 80);    /* color of the scroll thumb */
  border-radius: 5px;       /* roundness of the scroll thumb */
  border: none;  /* creates padding around scroll thumb */
}

.flip-card {
    perspective: 1000px;
  }
  
  .flip-card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  

